/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/browser-apis/
 */

import "./src/styles/bootstrap.min.css"
import "./src/styles/plugins.css"
import "./src/styles/type.css"
import "./src/styles/style.css"
import "./src/styles/color/purple.css"
import "./src/styles/font/font2.css"
import "./src/styles/overrides.css"

import "bootstrap/dist/js/bootstrap.min.js"
import * as insights from "insights-js"

export const onInitialClientRender = () => {
  if (!process.env.GATSBY_DEV) {
    insights.init("KY69wEhhx2Nu9IW0")
    insights.trackPages()
  }
}
